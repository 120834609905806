<template>
  <div>
    <container>
      <div class="d-flex justify-center align-center pos-r charging">
        <BtnMenu />
        <h1 class="h1">Чем заряжаться</h1>
        <a @click="modelAlert">
          <svg-icon
            class="alert-circle pointer pos-r"
            height="40"
            name="alert-circle"
            width="40"
          ></svg-icon>
        </a>
        <div v-if="isAlert" class="alert pos-a">
          <div class="pos-r">
            <a @click="modelAlert">
              <svg-icon
                class="pos-a cross pointer"
                height="24"
                name="x"
                width="24"
              ></svg-icon>
            </a>
            <p class="alert__text">
              Taycan заряжается устройствами с коннекторами Type 2 и CCS, самыми
              распространенными в Европе. Для зарядки можно использовать Mobile
              Charger (в комплекте), питающийся от обычной электросети, Home
              Energy Manager, интеллектуальную систему для вашего дома, либо
              электрозаправки в городе и на трассах.
            </p>
          </div>
        </div>
      </div>
      <div class="charging-wrap">
        <div class="charging-connectors-wrap">
          <h2 class="h2">Типы коннекторов</h2>
          <div class="charging-connectors">
            <div class="charging-connectors__item js-ccs">
              <img
                class="charging-connectors__item-img imgWhite"
                src="@/assets/img/connector-ccs.png"
                alt=""
              /><img
                class="charging-connectors__item-img imgblue"
                src="@/assets/img/connector-ccs-blue.png"
                alt=""
              />
              <span class="charging-connectors__item-text">CCS</span>
            </div>
            <div class="charging-connectors__item js-type2">
              <img
                class="charging-connectors__item-img imgWhite"
                src="@/assets/img/connector-type2.png"
                alt=""
              />
              <img
                class="charging-connectors__item-img imgblue"
                src="@/assets/img/connector-type2-blue.png"
                alt=""
              />
              <span class="charging-connectors__item-text">Type 2</span>
            </div>
          </div>
          <div class="charging-check">
            <router-link to="/verification" class="charging-check__link">
              <span class="charging-check__link-text">
                Пройти предварительную проверку возможности установки зарядного
                оборудования Porsche Charging
              </span>
              <svg-icon
                class="charging-check__link-img"
                name="arrow-link"
              ></svg-icon>
            </router-link>
          </div>
        </div>
      </div>
    </container>
    <div class="charging-type">
      <container><h2 class="h2">Виды зарядок от Porsche</h2></container>
      <div class="charging-type-slider">
        <div id="chargers-slider" class="embla">
          <div ref="chargersSlider" class="embla__viewport">
            <div class="embla__container">
              <div class="embla__slide">
                <img
                  class="chargers-slider__img"
                  src="@/assets/img/charging/slide-1.png"
                />
                <container
                  ><div class="info-wrap">
                    <h2>Porsche Mobile Charger Plus</h2>
                    <p>
                      Базовое комплектное устройство зарядки Вашего Taycan.
                      Mobile Charger Plus подключается к обычной электросети и
                      не требует дополнительной установки. Опционально
                      предлагается устройство Mobile Charger Connect с сенсорным
                      экраном и интеграцией в приложение Porsche Connect, что
                      позволяет узнать время зарядки или текущий уровень заряда
                      батареи.
                    </p>
                  </div>
                </container>
              </div>
              <div class="embla__slide">
                <img
                  class="chargers-slider__img"
                  src="@/assets/img/charging/slide-2.png"
                />
                <container
                  ><div class="info-wrap">
                    <h2>Home Energy Manager</h2>
                    <p>
                      Интеллектуальное устройство управления, для установки в
                      распределительном электрическом щитке и постоянной связи с
                      зарядным устройством. Home Energy Manager следит за тем,
                      чтобы потребители электричества в доме получали достаточно
                      энергии, пока Taycan заряжается в гараже. Для оценки
                      условий зарядки в доме рекомендуется провести
                      предварительную проверку, а после обратиться к
                      официальному дилеру Porsche.
                    </p>
                  </div>
                </container>
              </div>
              <div class="embla__slide">
                <img
                  class="chargers-slider__img"
                  src="@/assets/img/charging/slide-3.png"
                />
                <container
                  ><div class="info-wrap">
                    <h2>Porsche Turbo Charging</h2>
                    <p>
                      Дополнительную возможность зарядки предлагает сервис
                      Porsche Turbo Charging в отдельных центрах Porsche. Здесь
                      Вы в оптимальных условиях за пять минут запасетесь
                      энергией на следующие 100 км пути. Так же благодаря
                      бортовому зарядному устройству (постоянного тока)
                      мощностью 150 кВт Вы можете пользоваться общественными
                      зарядными станциями, рассчитанными на напряжение 400
                      вольт.
                    </p>
                  </div>
                </container>
              </div>
              <div class="embla__slide">
                <img
                  class="chargers-slider__img"
                  src="@/assets/img/charging/slide-4.png"
                />
                <container
                  ><div class="info-wrap">
                    <h2>Porsche Charger Connect</h2>
                    <p>
                      Кроме того, предлагаются многочисленные возможности
                      зарядки переменным током — например, в ресторанах,
                      гостиницах или в центральной части городов.
                    </p>
                  </div>
                </container>
              </div>
            </div>
          </div>
          <div class="embla__button embla__button--prev" @click="scrollPrev">
            <svg-icon
              class="charging-type__slider-arrow embla__button__svg"
              name="charging-arrow-left"
            ></svg-icon>
          </div>
          <div class="embla__button embla__button--next" @click="scrollNext">
            <svg-icon
              class="charging-type__slider-arrow embla__button__svg"
              name="charging-arrow-right"
            ></svg-icon>
          </div>
        </div>

        <!--  <div class="sl-button-prev"></div>
          <div class="sl-button-next"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Container from "../components/Container/Container.vue";
import SvgIcon from "../components/IconBase";
import EmblaCarousel from "embla-carousel";
import BtnMenu from "../components/BtnMenu.vue";
/* import { setupPrevNextBtns } from "../slider/prevAndNextButtons"; */

export default {
  data: () => {
    return {
      isAlert: false,
      embla: null,
    };
  },
  components: {
    Container,
    SvgIcon,
    BtnMenu,
  },
  methods: {
    modelAlert() {
      this.isAlert = !this.isAlert;
    },
    scrollPrev() {
      this.embla.scrollPrev();
    },
    scrollNext() {
      this.embla.scrollNext();
    },
  },
  mounted() {
    /*  containScroll: "keepSnaps",
      align: "center",
      skipSnaps: false, */
    this.embla = EmblaCarousel(this.$refs.chargersSlider, {
      loop: true,
      skipSnaps: false,
    });
    let ccs = document.querySelector(".charging-connectors .js-ccs");
    let type2 = document.querySelector(".charging-connectors .js-type2");
    /*     let ccsImg = document.querySelector(
      ".charging-connectors .js-ccs .charging-connectors__item-img"
    );
    let type2Img = document.querySelector(
      ".charging-connectors .js-type2 .charging-connectors__item-img"
    ); */
    this.embla.on("select", () => {
      if (this.embla.selectedScrollSnap() == 2) {
        ccs.classList.add("active");
        type2.classList.remove("active");
        /* ccsImg.src = "img/connector-ccs-blue.png";
        type2Img.src = "img/connector-type2.png"; */
      } else {
        type2.classList.add("active");
        ccs.classList.remove("active");
        /* ccsImg.src = "img/connector-type2-blue.png";
        type2Img.src = "img/connector-ccs.png"; */
      }
    });
    if (this.embla.selectedScrollSnap() == 2) {
      ccs.classList.add("active");
      type2.classList.remove("active");
    } else {
      type2.classList.add("active");
      ccs.classList.remove("active");
    }
    /* const prevBtn = document.querySelector(".embla__button--prev");
    const nextBtn = document.querySelector(".embla__button--next"); */
  },
};
</script>

<style>
.charging .alert {
  width: 347px;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;
  background-color: #3ec5fc;
  top: 163px;
  right: 46px;
  z-index: 100;
  height: auto;
  bottom: auto;
}
.charging {
  position: relative;
  padding-top: 100px;
}
.charging-check__link {
  display: block;
}
.charging-type {
  /* display: none; */
}
.charging-type .embla {
  overflow: hidden;
}
.charging-type .embla__container {
  display: flex;
}
.charging-type .embla__slide {
  position: relative;
  flex: 0 0 100%;
  width: 100%;
}
.chargers-slider__img {
  display: block;
  width: 100%;
}
.charging-type-slider {
  position: relative;
}
.charging-connectors-wrap {
  display: flex;
  flex-wrap: wrap;
}
.charging-connectors-wrap h2 {
  width: 100%;
}
.charging-connectors {
  width: 50%;
  display: flex;
  margin-top: 24px;
}
.charging-connectors__item + .charging-connectors__item {
  margin-left: 30px;
}
.charging-connectors__item {
  display: flex;
  flex-direction: column;
}
.charging-connectors__item-img {
}
.charging-connectors__item-text {
  display: block;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 16px;
}
.charging-check {
  width: 50%;
}
.charging-check__link {
  display: block;
  position: relative;
  border: 1px solid #ffffff;
  padding: 24px 80px 24px 24px;
  margin-top: 35px;
  max-width: 402px;
  margin-left: auto;
}
.charging-check__link-text {
}
.charging-check__link-img {
  position: absolute;
  display: block;
  right: 24px;
  top: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
}
.charging-type {
  margin-top: 56px;
  padding-bottom: 60px;
}
.charging-type h2 {
  margin-bottom: 24px;
}
.info-wrap {
  margin-top: 56px;
  padding-left: 32px;
  border-left: 4px solid #3ec5fc;
}
.info-wrap h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 100%;
  color: #ffffff;
}
.info-wrap p {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.charging-type .embla__button svg {
  width: 100%;
  height: 100%;
}
.charging-type .embla__button--prev {
  position: absolute;
  top: 150px;
  left: 40px;
  width: 72px;
  height: 72px;
}
.charging-type .embla__button--next {
  position: absolute;
  top: 150px;
  right: 40px;
  width: 72px;
  height: 72px;
}
.active {
}
.active .charging-connectors__item-text {
  color: #3ec5fc !important;
}
/* .active .charging-connectors__item-img {
  background-color: #3ec5fc !important;
} */
.active .charging-connectors__item-img.imgWhite {
  display: none !important;
}
.active .charging-connectors__item-img.imgblue {
  display: block !important;
}
.charging-connectors__item-img.imgblue {
  display: none;
}
@media (max-width: 1023px) {
  .charging {
    padding-top: 75px;
  }
  .charging .alert {
    top: 138px;
  }
  .charging-wrap {
    padding-top: 30px;
  }
  .charging-connectors__item-img {
    width: 95px;
    height: 133px;
  }
  .charging-connectors {
    margin-top: 20px;
  }
  .charging-connectors__item-text {
    font-size: 12px;
    margin-top: 12px;
  }
  .charging-check__link {
    padding: 18px 62px 18px 18px;
    margin-top: 29px;
  }
  .charging-check__link-text {
    font-size: 16px;
  }
  .charging-type {
    margin-top: 44px;
    padding-bottom: 60px;
  }
  .charging-type h2 {
    margin-bottom: 20px;
  }
  .charging-type .embla__button--next {
    width: 56px;
    height: 56px;
    top: 119px;
  }
  .charging-type .embla__button--prev {
    width: 56px;
    height: 56px;
    top: 119px;
  }
  .info-wrap{
    margin-top: 44px;
    border-left: 3px solid #3ec5fc;
    padding-left: 24px;
  }
  .charging-type .info-wrap h2{
    font-size: 32px;
  }
  .info-wrap p{
    font-size: 16px;
  }
}
</style>